@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

.impact-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.self-powered,
.solar-offset {
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

.impact {
  width: 300px;
}

#percentage {
  font-weight: 700;
  font-size: 4.25em;
  color: rgb(229 231 235);
  position: absolute;
  top: 50%;
  margin-left: 50px;
  transform: translate(-50%, -50%);
}

#impact-donut {
  position: absolute;
  top: 50%;
  margin-left: 50px;
  transform: translate(-50%, -50%);
}

li.solarMarker::before {
  content: "\2B24";
  position: relative;
  top: -4px;
  margin: 8px 10px 0 -12px;
  vertical-align: middle;
  color: #EA9300;
  font-size: 15px;
}

li.powerwallMarker::before {
  content: "\2B24";
  position: relative;
  top: -4px;
  margin: 8px 10px 0 -12px;
  vertical-align: middle;
  color: #72FF13;
  font-size: 15px;
}

li.gridMarker::before {
  content: "\2B24";
  position: relative;
  top: -4px;
  margin: 8px 10px 0 -12px;
  vertical-align: middle;
  color: #A5ADAD;
  font-size: 15px;
}

.discharge,
.charge,
.standby {
  font-size: .7em;
}

.discharge {
  color: #183EFA;
}

.charge {
  color: #72FF13;
}

.standby {
  color: #A5ADAD;
}

@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }

  li.solarMarker::before,
  li.powerwallMarker::before,
  li.gridMarker::before {
    font-size: 10px;
    margin: 8px 10px 0 0;
  }

  .solarMarker,
  .powerwallMarker,
  .gridMarker {
    font-size: 1rem;
  }

  .impact {
    width: 150px;
  }

  #percentage {
    font-size: 1.75rem;
  }

  #impact-donut {
    margin-left: 228px;
    top: 19%;
  }

  .offset {
    font-size: 1rem;
  }

  .self-powered,
  .solar-offset {
    position: relative;
    left: -10px;
    transform: none;
  }

  .impact-div {
    margin-bottom: 35px;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  color: rgb(156 163 175);
  font-size: .9rem;
}

.e-input-group .e-clear-icon,
.e-input-group.e-control-wrapper .e-clear-icon .e-range-icon .e-active {
  color: rgb(204 0 0);
}

.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) span.e-input-group-icon {
  color: rgb(204 0 0)
}

/* To specify background color and font size */
.e-input-group .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  background-color: rgb(204 0 0);
  font-size: 14px;
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  color: rgba(204, 0, 0, .75)
}

.e-grid .e-summaryrow .e-summarycell {
  font-weight: bold;
  font-size: 1.25em;
}

.e-rowcell.customcss,
.e-templatecell.customcss {
  color: rgb(204, 0, 0) !important;
}

/* .customcss {
  background-color: rgb(255, 0, 0);
} */

#daterangepicker::placeholder {
  color: rgba(156, 163, 175, .25);
}

/* 
.tr-header, .tr-footer {
  background-color: rgb(51 55 62) !important;
  color: rgb(229 231 235) !important;
}

.tr-body {
  background-color: rgb(51 55 62) !important;
  color: rgb(156 163 175)
}

td {
  margin: 5px 0 !important;
}

td:not(:first-of-type) {
  text-align: right;
}

td:first-of-type {
  text-align: left;
}

th:not(:first-of-type) {
  text-align: right;
}

th:first-of-type {
  text-align: left;
}

tr.tr-footer > th {
  margin-top: 5px !important;
  border-top: 1px rgb(156 163 175) solid;
  padding-top: 10px;
} */